import React from "react"
import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main,
    fontStyle: "italic",
  },
}))

const FormErrors = ({ formErrors }) => {
  const classes = useStyles()

  return (
    <Box my="1rem" height="1rem">
      {Object.keys(formErrors).map((fieldName, i) => {
        if (formErrors[fieldName].length > 0) {
          return (
            <Typography className={classes.error} key={i}>
              {fieldName} {formErrors[fieldName]}
            </Typography>
          )
        } else {
          return ""
        }
      })}
    </Box>
  )
}

export default FormErrors

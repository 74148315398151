import React from "react"
import { Link as RouterLink } from "gatsby"

import { Link } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(theme => ({
  link: {
    fontWeight: "700",
    textDecoration: "none",
  },
}))

const CoLink = ({ to, children, ...rest }) => {
  const classes = useStyles()
  return (
    <Link className={classes.link} component={RouterLink} to={to} {...rest}>
      {children}
    </Link>
  )
}

export default CoLink

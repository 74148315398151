import { createMuiTheme, responsiveFontSizes } from "@material-ui/core"

import colors from "./utility/colors"

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 730,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#64BC00",
      dark: "#0E870B",
    },
    secondary: {
      main: colors.white[400],
    },
    error: {
      main: "#930000",
    },
    text: {
      primary: colors.grey[700],
      secondary: colors.grey[400],
    },
    background: {
      default: colors.white[400],
    },
    action: {
      selected: colors.green[400],
      hover: colors.green[400],
    },
  },
  typography: {
    h1: {
      fontFamily: "Futura",
      fontWeight: "700",
      fontSize: "5.6rem",
      color: colors.white[400],
    },
    h2: {
      fontFamily: "Refreshen and Softening",
      fontSize: "6.25rem",
      fontWeight: "400",
      color: colors.grey[400],
      margin: "1rem 0",
    },
    body1: {
      fontWeight: "300",
      fontSize: "1.25rem",
      color: colors.grey[700],
      lineHeight: "1.2",
    },
    body2: {
      fontWeight: "300",
      fontSize: "1.25rem",
      color: colors.grey[400],
      lineHeight: "1.2",
    },
  },
  link: {
    primary: colors.grey[700],
    hover: colors.green[700],
    active: colors.green[700],
  },
  // Text color of buttons
  button: {
    primary: colors.white[400],
    secondary: colors.green[400],
  },
  data: {
    janeApp: "#",
  },
})

export default responsiveFontSizes(theme)

import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core"

import colors from "../utility/colors"
import CoButton from "./co-button"
import BgImage from "../components/background-image"

const useStyles = makeStyles(theme => ({
  overview: {
    backgroundRepeat: "no-repeat",
    backgroundSize: props =>
      props.index ? "cover !important" : "initial !important",
    backgroundPosition: props => (props.index ? "left center" : "left top"),
    padding: "0 1rem",
    height: props => props.heroVh,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  headline: {
    color: props => (props.index ? colors.white[400] : colors.grey[700]),
    textShadow: "0 2px 4px rgba(0,0,0,0.5)",
    maxWidth: props => props.headlineMaxWidth,
  },
  tagline: {
    color: props => (props.index ? colors.white[400] : colors.grey[400]),
    marginBottom: theme.spacing(4),
    textShadow: props =>
      props.index && "0 1px 0 rgba(0,0,0,0.5), 0 2px 4px rgba(0,0,0,0.5)",
    maxWidth: props => props.taglineMaxWidth,
    fontWeight: props => props.index && "400",
  },
  contentContainer: {},
}))

const Hero = props => {
  const classes = useStyles(props)
  const theme = useTheme()
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"))
  const {
    headline,
    tagline,
    primaryBtnLabel,
    secondaryBtnLabel,
    primaryHref,
    secondaryHref,
    index,
    onOpenDialog,
  } = props

  return (
    <React.Fragment>
      <BgImage
        imgName={index ? "hero-index.jpg" : "hero-content.jpg"}
        className={classes.overview}
      >
        <Box
          className={classes.contentContainer}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography className={classes.headline} variant="h1" component="h1">
            {headline}
          </Typography>
          <Typography className={classes.tagline} variant="body2">
            {tagline}
          </Typography>
          <Box>
            {secondaryBtnLabel && (
              <CoButton type="secondary" href={secondaryHref}>
                {secondaryBtnLabel}
              </CoButton>
            )}
            {!matchesSm && (
              <CoButton
                type="primary"
                href={primaryHref}
                onClick={() => onOpenDialog()}
              >
                {primaryBtnLabel}
              </CoButton>
            )}
          </Box>
        </Box>
      </BgImage>
    </React.Fragment>
  )
}

/**
 * @param {string} headline
 * @param {string} headlineMaxWidth
 * @param {string} tagline
 * @param {string} taglineMaxWidth
 * @param {string} primaryBtnLabel
 * @param {string} secondaryBtnLabel
 * @param {boolean} index Styles applied if on index page. The headline and taglines.
 */
Hero.propTypes = {
  headline: PropTypes.string,
  headlineMaxWidth: PropTypes.string,
  tagline: PropTypes.string,
  taglineMaxWidth: PropTypes.string,
  primaryBtnLabel: PropTypes.string,
  secondaryBtnLabel: PropTypes.string,
  index: PropTypes.bool,
}

export default Hero

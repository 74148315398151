import React from "react"

import { Box, Container, Typography, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  root: {
    fontSize: "0.7rem",
  },
})

const Footer = () => {
  const classes = useStyles()

  return (
    <footer>
      <Container>
        <Box
          textAlign="center"
          height="5rem"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography className={classes.root}>
            This website was designed by Ember Navarro and built by Wayne Huang.
            © {new Date().getFullYear()}, Crafted Origin Media in coordination
            with Green Stone Wellness Clinic. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </footer>
  )
}

export default Footer

import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Typography from "@material-ui/core/Typography"

import greenstoneLogo from "../images/greenstone-logo.png"
import CoLink from "./co-link"

const useStyles = makeStyles(theme => ({
  dialog: {
    "& .MuiDialog-container": {
      display: "block",
    },
    "& .MuiDialog-paper": {
      background: "transparent",
      boxShadow: "none",
      maxWidth: "65%",
      height: "72%",
      margin: "20px auto 0",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    background: `url('./dialog-notice-background.png') no-repeat`,
    backgroundSize: "72%",
    backgroundPosition: "50%",
    paddingTop: "160px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      paddingTop: "130px",
    },
  },
  title: {
    width: "210px",
    fontSize: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      width: "140px",
    },
  },
  logo: {
    width: "300px",
    [theme.breakpoints.down("md")]: {
      width: "220px",
    },
  },
  noLongerText: {
    width: "390px",
    fontSize: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      width: "230px",
    },
  },
  questionsText: {
    width: "460px",
    marginBottom: "40px",
    fontSize: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      width: "190px",
      marginBottom: "10px",
    },
  },
}))

export default function DialogClosedBooking(props) {
  const classes = useStyles()
  const { open, onCloseDialog } = props

  return (
    <Dialog
      aria-labelledby="closed-booking"
      open={open}
      className={classes.dialog}
    >
      <div className={classes.container}>
        <Typography className={classes.title}>
          Please be advised that as of January 2022,
        </Typography>
        <img
          src={greenstoneLogo}
          alt="greenstone wellness"
          className={classes.logo}
        />
        <Typography className={classes.noLongerText}>
          we are no longer taking on any new clients.
        </Typography>
        <Typography className={classes.questionsText}>
          If you have further questions please do not hesitate to reach out to
          us using our{" "}
          <CoLink to="/#contact" onClick={() => onCloseDialog()}>
            CONTACT FORM
          </CoLink>
          .
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          style={{ padding: "10px 88px" }}
          onClick={() => onCloseDialog()}
        >
          OK
        </Button>
      </div>
    </Dialog>
  )
}

DialogClosedBooking.propTypes = {
  open: PropTypes.bool.isRequired,
}

import React from "react"
import PropTypes from "prop-types"

import { Container, Typography, makeStyles, Box } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  container: props => ({
    textAlign: "center",
    marginBottom: props.mb,
    ...props.mainContentStyles,
  }),
  image: {
    width: "100%",
  },
}))

const MainContent = props => {
  const classes = useStyles(props)
  const { children, headingImageSrc, headingImageAlt, title } = props

  return (
    <Container className={classes.container} maxWidth="sm">
      {headingImageSrc && (
        <Box>
          <img
            className={classes.image}
            src={headingImageSrc}
            alt={headingImageAlt}
          />
        </Box>
      )}
      <Typography variant="h2">{title}</Typography>
      <Box textAlign="justify">{children}</Box>
    </Container>
  )
}

MainContent.propTypes = {
  children: PropTypes.node,
  headingImageSrc: PropTypes.string,
  headingImageAlt: PropTypes.string,
  mainContentStyles: PropTypes.object,
  mb: PropTypes.string,
}

MainContent.defaultProps = {
  mb: "4rem",
}

export default MainContent

import React from "react"
import PropTypes from "prop-types"
import { Button } from "@material-ui/core"

import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(theme => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3.3)}px`,
    width: "200px",
    height: "64px",
    fontSize: "1rem",
    border: props =>
      props.type === "secondary" && `2px solid ${theme.button.secondary}`,
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: props =>
        props.type === "primary"
          ? theme.button.secondary
          : theme.button.primary,
    },
  },
  label: {
    color: props => {
      if (props.type === "primary") {
        return theme.button.primary
      }
      return theme.button.secondary
    },
  },
  contained: {
    boxShadow: props =>
      props.type === "primary" ? "0 4px 10px rgba(0,0,0,0.5)" : "none",
  },
}))

const CoButton = ({ type, children, href, ...rest }) => {
  const classes = useStyles({ type, children, href })

  return (
    <Button
      {...rest}
      variant="contained"
      color={type}
      className={classes.root}
      classes={{
        label: classes.label,
        contained: classes.contained,
      }}
      href={href}
    >
      {children}
    </Button>
  )
}

/**
 * @param {string} type String like "primary" or "secondary"
 */
CoButton.propTypes = {
  type: PropTypes.string,
}

export default CoButton

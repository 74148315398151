import React from "react"

import FormContact from "./form-contact"
import BackgroundImage from "./background-image"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "900px",
  },
})

const ContactSection = props => {
  const classes = useStyles()

  return (
    <BackgroundImage
      id="contact"
      imgName="contact-bg.jpg"
      style={{ padding: "3rem 0" }}
      className={classes.container}
    >
      <FormContact onOpenDialog={props.onOpenDialog} />
    </BackgroundImage>
  )
}

export default ContactSection

const colors = {
  green: {
    400: "#64BC00",
    700: "#0E870B",
  },
  blue: {
    400: "#D4ECFE",
  },
  white: {
    400: "#ffffff",
  },
  grey: {
    400: "#424242",
    700: "#202020",
  },
}

export default colors
